import { useTranslation } from 'react-i18next';
import { TableColumnType, TableRowActionType } from 'component/new_design/base/Table/types';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { Table } from 'component/new_design/base/Table';
import { formatTableDate } from 'utils/dateFormat';
import { AutomatedBackup, useGetAutomatedBackupsNew } from 'api/backups';
import RestoreIcon from 'component/new_design/icons/Restore.svg?react';
import { useState } from 'react';
import { AutomatedBackupRestoreDialog } from '../../SiteDetails/Backups/AutomatedBackupRestoreDialog';
import { BackupStatusCell } from './BackupStatusCell';
import Server from 'component/new_design/icons/Server.svg?react';
import { EmptyState } from 'component/new_design/base/EmptyState';

interface AutomatedBackupsTableProps {
  readonly siteId: string;
}

export function AutomatedBackupsTable({ siteId }: AutomatedBackupsTableProps) {
  const { t, i18n } = useTranslation();

  const [recoveryPointToRestore, setRecoveryPointToRestore] = useState<string | null>();

  const tableState = usePaginatedListState({ urlStatePrefix: 'automated' });

  const { data, isLoading, refetch } = useGetAutomatedBackupsNew(siteId, tableState);

  const columns: TableColumnType<AutomatedBackup>[] = [
    {
      label: t('label'),
      key: 'recoveryPoint',
    },
    {
      label: t('status'),
      key: 'status',
      renderValue: backup => <BackupStatusCell backup={backup} />,
    },
    {
      label: t('created'),
      key: 'created_at',
      renderValue: backup => formatTableDate(i18n.language, backup.created_at),
    },
  ];

  const rowActions: TableRowActionType<AutomatedBackup>[] = [
    {
      icon: <RestoreIcon />,
      label: t('restore'),
      onClick: backup => setRecoveryPointToRestore(backup.recoveryPoint!),
    },
  ];

  return (
    <>
      <Table
        title={t('automated_backups')}
        label={t('automated_backups')}
        columns={columns}
        data={data?.data.result || []}
        totalRowCount={data?.data.metadata?.total || 0}
        isLoading={isLoading}
        state={tableState}
        rowActions={rowActions}
        emptyState={
          <EmptyState
            icon={<Server />}
            title={t('no_data_yet')}
            description={t('here_will_be_list_of_automated_platform_backups')}
          />
        }
      />
      {recoveryPointToRestore ? (
        <AutomatedBackupRestoreDialog
          siteId={siteId}
          backupId={recoveryPointToRestore}
          onSuccess={refetch}
          onClose={() => setRecoveryPointToRestore(null)}
        />
      ) : null}
    </>
  );
}
